import {
  exportRequest,
  postBodyRequest
} from '@/utils/axios'

export function aging (params) {
  return postBodyRequest('/trans/kanban/aging', params)
}

export function pickDetail (params) {
  return postBodyRequest('/trans/kanban/pick/detail', params)
}

export function pickPage (params) {
  return postBodyRequest('/trans/kanban/pick/page', params)
}

export function locationDetail (params) {
  return postBodyRequest('/trans/kanban/location/detail', params)
}

export function pickExport (params, fileName) {
  return exportRequest('/trans/kanban/pick/export', params, fileName)
}

export function locationExport (params, fileName) {
  return exportRequest('/trans/kanban/location/export', params, fileName)
}

export function vehicleDetail (params) {
  return postBodyRequest('/trans/kanban/vehicle/detail', params)
}

export function vehiclePage (params) {
  return postBodyRequest('/trans/kanban/vehicle/page', params)
}

export function vehicleExport (params, fileName) {
  return exportRequest('/trans/kanban/vehicle/export', params, fileName)
}

export function getData (params) {
  return postBodyRequest('/anal/monitor/getVehicleMonitor', params)
}

export function getEmpData (params) {
  return postBodyRequest('/anal/monitor/getMonitorEmpList', params)
}

export function getEmpVehData (params) {
  return postBodyRequest('/anal/monitor/getMonitorEmpVehicleList', params)
}