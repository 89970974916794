<template>
  <div style="width: 100%; height: 100%; position: relative">
    <div>
      <a-form layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.empName" placeholder="调度员" />
        </a-form-item>
        <a-form-item label="">
          <div class="operate-btn">
            <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
            <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div>
      <div style="margin-bottom: 15px" v-for="(item, index) in dataList" :key="index">
        <div class="table-detail">
          <div class="detail-name">{{ item.name }}</div>
          <div class="detail-num" :style="{ color: colors[monitorLevel?.value] }">
            {{ item.num }}
          </div>
          <div>
            <a @click="openDetail(item, index)">{{ !item.detailShow ? "展开＞＞" : "收起＜＜" }}</a>
          </div>
        </div>
        <div class="detail-table" v-show="item.detailShow">
          <div class="detail-data" v-show="!item.detailLoading && !tableShow" v-for="v in item.detailList" :key="v.NO">
            <div style="display: flex;justify-content: space-between;">
              <div style="width:180px">
                <a style="color: #0066cc" @click="lookDetail(v)">{{ v.number + ". " }} {{ v.vinNo }}</a>
              </div>
              <div style="width:50px;">{{ v.salesman }}</div>
              <div>{{ v.orderId }}</div>
              <div :style="{ color: colors[monitorLevel?.value] }">{{ v.context }}</div>
            </div>
            <div v-show="v.type">
              <div style="display: flex; justify-content: space-between">
                <span>异常上报人：{{ v.explainer }}</span>
                <span>{{ v.explainTime }}</span>
                <span>{{ v.type?.label }}</span>
              </div>
              <div>
                <span>异常内容：{{ v.exceptionExplain }}</span>
              </div>
            </div>
          </div>
          <div v-show="!item.detailLoading" v-if="tableShow">
            <a-list size="small" bordered :data-source="item.detailList">
              <template #renderItem="{ item }">
                <a-list-item>
                  <a-list-item-meta>
                    <template #description v-if="item.type">
                      <div>
                        <div style="display: flex; justify-content: space-between">
                          <span>异常上报人：{{ item.explainer }}</span>
                          <span>{{ item.explainTime }}</span>
                          <span>{{ item.type?.label }}</span>
                        </div>
                        <div>
                          <span>异常内容：{{ item.exceptionExplain }}</span>
                        </div>
                      </div>
                    </template>
                    <template #title>
                      <div style="display: flex; justify-content: space-between">
                        <div style="width: 5%; text-align: left">{{ item.number }}</div>
                        <div style="width: 10%; text-align: center">
                          <a @click="lookDetail(item)">{{ item.vinNo }}</a>
                        </div>
                        <div style="width: 10%; text-align: center">
                          {{ item.detailAddress }}
                        </div>
                        <div style="flex: 1; text-align: center">{{ item.address }}</div>
                        <div style="" :style="{ color: colors[monitorLevel?.value], width: '18%', textAlign: 'right' }">
                          {{ item.context }}
                        </div>
                      </div>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </template>
            </a-list>
          </div>
          <div class="loading" v-show="item.detailLoading">
            <a-spin size="large" />
          </div>
        </div>
      </div>
      <a-pagination size="small" :current="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
        :show-total="pagination.showTotal" @change="listChange" />
    </div>
    <div class="loading" v-show="loading">
      <a-spin size="large" />
    </div>
  </div>
  <a-modal v-model:visible="detailShow" footer="" width="65%" :bodyStyle="{ padding: '15px', paddingTop: 0 }">
    <template #title>
      <WindowDetail v-if="detailData.vinNo" :detailData="detailData" />
    </template>
    <div class="left-content" style="width: auto">
      <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="detailData" :detailType="true"
        @statusShow="transportModelShowStatus" />
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import { getEmpData, getEmpVehData } from '@/api/dataCenter/prescription'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'
import WindowDetail from '@/views/components/vinNo/windowDetail.vue'

export default {
  props: {
    searchFormData: Object
  },
  components: {
    TransportModel,
    WindowDetail
  },
  setup (props) {
    const transportModelRef = ref(null)
    const state = reactive({
      detailShow: false,
      tableShow: false,
      loading: false,
      detailData: {},
      dataList: [],
      detailList: [],
      searchForm: { empName: props.searchFormData.empName || '' },
      mirrorSearchForm: {},
      vehicleMonitorType: {},
      monitorLevel: {},
      colors: {
        0: '#0066CC', // 正常
        1: '#7948EA', // 预警
        2: '#F47920', // 超时
        3: '#E33C64', // 严重
        4: '#0066CC' // 总数
      },
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const setData = () => {
      state.searchForm = props.searchFormData
    }
    const loadData = (vehicleMonitorType, monitorLevel) => {
      if (vehicleMonitorType) {
        state.vehicleMonitorType = vehicleMonitorType
        if (vehicleMonitorType.value === 6) state.tableShow = true
        else state.tableShow = false
      }
      if (monitorLevel) state.monitorLevel = monitorLevel
      state.loading = true
      getEmpData({
        ...state.searchForm,
        monitorLevel: state.monitorLevel,
        vehicleMonitorType: state.vehicleMonitorType,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then((res) => {
        if (res.code === 10000) {
          state.dataList = []
          res.data.records.forEach((item, index) => {
            if (item.num > 0) {
              state.dataList.push({
                name: item.empName,
                id: item.empId,
                num: item.num,
                loading: false,
                detailList: [],
                detailShow: false,
                detailLoading: false
              })
            }
          })
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }
    const listChange = (e) => {
      state.pagination.current = e
      loadData()
    }
    const openDetail = (item, i) => {
      item.detailShow = !item.detailShow
      if (item.detailShow === true) {
        item.detailLoading = true
        getEmpVehData({
          monitorLevel: state.monitorLevel,
          vehicleMonitorType: state.vehicleMonitorType,
          empName: item.name,
          empId: item.id,
          current: 1,
          size: 9999
        }).then((res) => {
          if (res.code === 10000) {
            console.log(state.monitorLevel);
            state.dataList[i].detailList = []
            res.data.records.forEach((item, index) => {
              state.dataList[i].detailList.push({
                vinNo: item.vinNo,
                orderVehicleId: item.orderVehicleId,
                number: index + 1,
                exceptionExplain: item.exceptionExplain,
                explainTime: item.explainTime,
                explainer: item.explainer,
                type: item.type,
                salesman: item.salesman,
                orderId: item.orderId,
                address: item.startAddress + '—' + item.endAddress,
                detailAddress: item.location,
                context:state.monitorLevel?.value == 1?disDate(item.warningMinute): '超时' + item.hourTime + '小时' + item.minuteTime + '分钟'

              })
            })
          }
        }).finally(() => {
          item.detailLoading = false
        })
      }
    }
    const closeWindow = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.pagination.current = 1
      state.detailList = []
      state.dataList = []
    }
    const disDate = (minute) => {
      if(!minute) return ''
      if(isNaN(minute)) return ''
      if(minute<60){
        return  `还有${minute}分钟要超时`
      }else{
        let hour = Math.floor(minute/60)
        let min = minute%60
        return  `还有${hour}小时${min}分钟要超时`
      }
    }
    const transportModelShowStatus = (e) => {
      if (e.orderId) state.detailData = e
    }
    const lookDetail = (record) => {
      state.detailData = record
      state.detailShow = true
      // state.carDetailShow = true
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 100)
    }
    return {
      ...toRefs(state),
      transportModelRef,
      searchData,
      setData,
      resetData,
      loadData,
      listChange,
      openDetail,
      closeWindow,
      lookDetail,
      transportModelShowStatus,
      disDate
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";

.table-detail {
  padding: 10px;
  margin-top: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  border: 1px solid #d7d7d7;

  .detail-name {
    width: 50px;
    color: #344563;
    font-size: 14px;
  }

  .detail-num {
    font-size: 20px;
  }
}

.detail-table {
  width: 100%;
  position: relative;

  // top: 100%;
  // left:0;
  .detail-data {
    padding: 10px;
    border: 1px solid #d7d7d7;
    border-top: none;
  }
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
